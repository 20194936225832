<template>
    <TheSnapshotBundleList :bundleType="distributionReasons.MATERIAL_SHARING"></TheSnapshotBundleList>
</template>

<script>
import { enumsData } from '@/mixins/enums'
const TheSnapshotBundleList = () => import('../components/TheSnapshotBundleList')
export default {
    mixins: [enumsData],
    components: {
        TheSnapshotBundleList
    }
}
</script>
